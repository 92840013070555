import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions, base } from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import UploadImage from '../utils/UploadImage';
import ReactPlayer from "react-player";
import {validUrl} from "../utils/HelpfulFunction";
import {PhotoshopPicker} from "react-color";

class SetUpQuestions extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            questionsList: [],
            editingTicket: false,
            selectedQuestion: {},
            newAnswerText: '',
            newAnswerImage: null,
            newAnswerImagePreview: {},
            newTopImage: null,
            newTopImagePreview: {},
            currentAnswerList: [],
            currentQuestionTitle: '',
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            editTicketId: "",
            loading:true,
            imagesAsAnswers: false,
            advanced: false,
            add_video: false,
            videoLength: null,
            videoLink: "",
            description: "",
            answerDescriptionLength: 250
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
    }

    componentDidMount() {
      this.questionsListRef = base.syncState(`questionsList`, {
        context: this,
        state: 'questionsList',
        asArray: true
      });
      this.setState({loading:false})
    }

    componentWillUnmount() {
      base.removeBinding(this.questionsListRef);
    }

    handleChange(event){
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [event.target.name]: value });
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      const nameToUpdate = myArgument + "Preview"
      const fileToUpdate = files[0]
      this.setState({
        [nameToUpdate]: ({
          fileToUpdate,
          preview: URL.createObjectURL(fileToUpdate)
        })
      })
    }

    handleVideoChange(evt){
        const name_of_file = evt.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true})
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false})
            if(res && res.error){
                swal({
                    title: 'Video cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res && res.imageUrl) {
                vm.setState({
                    fileUploaded: true,
                    videoLink: res.imageUrl
                });
            } else {
                swal({
                    title: 'Video cannot be uploaded',
                    text: "Something went wrong, please re-upload your video and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    openColorPicker(colorToChange){
        var oldColor = this.state[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        this.setState({
            [this.state.colorToUpdate]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    handleSwalWarning = (title, text) => {
        swal({
            title,
            text,
            type: 'warning',
            confirmButtonText: 'Ok',
        });
    };

    clearState = () => {
        this.setState({
            modal: false,
            selectedQuestion: {},
            currentAnswerList: [],
            videoLink: "",
            videoLength: null,
            add_video: false,
            showPhonePercentages: null,
            showScoreboardPercentages: null,
            advanced: false,
            answerBackgroundColor: "",
            answerTextColor: "",
            answerOutlineColor: "",
            answerProgressBarClient: ""
        });
    };

    async createQuestion() {
        let {
            currentQuestionTitle,
            selectedQuestion,
            currentAnswerList,
            add_video,
            videoLink,
            videoLength,
            showPhonePercentages,
            showScoreboardPercentages,
            answerBackgroundColor,
            answerTextColor,
            answerOutlineColor,
            answerProgressBarClient,
            newTopImagePreview,
            advanced,
            questionDescription
        } = this.state;

        const newTopImage = newTopImagePreview || "";
        if(newTopImage){
            newTopImagePreview = newTopImage.preview || "";
        }

        const questionTitle = currentQuestionTitle || selectedQuestion.questionText;

        if (!questionTitle) {
            return this.handleSwalWarning("No Question", 'Make sure to fill out the question section!');
        }

        if (add_video && videoLink && !validUrl(videoLink)) {
            return this.handleSwalWarning('Oh uh!', 'Video link is not a valid URL');
        }

        if (add_video && !videoLink) {
            return this.handleSwalWarning('Oh uh!', "You have indicated you want to add a video but haven't added a video link nor a video file");
        }

        if (!add_video) {
            videoLink = false;
            videoLength = false;
        }

        if (currentAnswerList.length <= 1) {
            return this.handleSwalWarning("Incorrect Answers", 'You need to add more answers to this question');
        }

        if (!advanced) {
            showPhonePercentages = null;
            showScoreboardPercentages = null;
            answerBackgroundColor = "";
            answerTextColor = "";
            answerOutlineColor = "";
            answerProgressBarClient = "";
            newTopImagePreview = "";
        }
        this.setState({loading: true})
        let topImage, topImagePreview;
        if(newTopImage && newTopImage.fileToUpdate){
            const response = await this.UploadImage.upload_file(newTopImage.fileToUpdate);
            if(response.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: response.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading: false})
                return
            }
            topImage = response.imageUrl;
            topImagePreview = newTopImagePreview;
        } else {
            topImage = newTopImagePreview;
            topImagePreview = newTopImagePreview;
        }

        currentAnswerList = currentAnswerList.map((answer) => ({ ...answer, answerImagePreview: null, description: answer.description || "" }));

        selectedQuestion = Object.keys(selectedQuestion).length === 0 ? null : selectedQuestion;

        const questionId = (selectedQuestion && selectedQuestion.id) || (selectedQuestion && selectedQuestion.key) || appDatabasePrimaryFunctions.ref().push().key;

        const questionToSave = {
            id: questionId,
            questionText: questionTitle,
            answers: currentAnswerList,
            showPhonePercentages: showPhonePercentages === undefined ? null : showPhonePercentages,
            showScoreboardPercentages: showScoreboardPercentages === undefined ? null : showScoreboardPercentages,
            videoLength: videoLength === undefined ? null : videoLength,
            videoLink: videoLink === undefined ? null : videoLink,
            answerBackgroundColor: answerBackgroundColor === undefined ? null : answerBackgroundColor,
            answerTextColor: answerTextColor === undefined ? null : answerTextColor,
            answerOutlineColor: answerOutlineColor === undefined ? null : answerOutlineColor,
            answerProgressBarClient: answerProgressBarClient === undefined ? null : answerProgressBarClient,
            topImage: topImage,
            topImagePreview: topImagePreview
        };
        if(questionDescription){
            questionToSave['questionDescription'] = questionDescription
        } else {
            questionToSave['questionDescription'] = null;
        }
      base.post(`questionsList/${questionId}`, {
        data: questionToSave,
        then: (err) => {
            if (!err) {
                this.clearState();
            }
            this.setState({loading: false})
        }
      });
    }

    async answerToLocalArray(){
        let array = this.state.currentAnswerList;
        let newAnswerText = this.state.newAnswerText || "";
        let description = this.state.description || "";
        newAnswerText = newAnswerText.trim();
        const newAnswerImage = this.state.newAnswerImagePreview || "";
        let newAnswerImagePreview = "";
        if(newAnswerImage){
            newAnswerImagePreview = newAnswerImage.preview || "";
        }
        // if(array.length>3){
        //     swal({
        //         title: "Too many answers",
        //         text: 'Only 4 answers are allowed per question',
        //         type: 'warning',
        //         confirmButtonText: 'Ok'
        //     })
        //     return;
        // }
        if(newAnswerText === ""){
            swal({
                title: "Answer is blank",
                text: 'Please enter an answer and try again',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        if(description.length > this.state.answerDescriptionLength){
            swal({
                title: "Hold On!",
                text: 'The answer description cannot be longer than ' + this.state.answerDescriptionLength + ' characters.',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        // if(newAnswerText.length > 72){
        //     swal({
        //         title: "Answer Is Too Long",
        //         text: 'Answer cannot be longer than 72 characters',
        //         type: 'warning',
        //         confirmButtonText: 'Ok'
        //     });
        //     return;
        // }
        if(newAnswerImage && newAnswerImage.fileToUpdate){
            this.setState({loading:true})
            this.UploadImage.upload_file(newAnswerImage.fileToUpdate).then(res => {
                this.setState({loading:false})
                if(res.error){
                    swal({
                        title: 'Image cannot be uploaded',
                        text: res.error,
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                } else if(res.imageUrl) {
                    const answerId = appDatabasePrimaryFunctions.ref().push().key;
                    let newAnswerObject = {answerText: newAnswerText, id: answerId, answerImage: res.imageUrl, answerImagePreview: newAnswerImagePreview, order:array.length+1, description: description};
                    array = array.concat(newAnswerObject);
                    this.setState({
                        currentAnswerList: array,
                        newAnswerText: "",
                        newAnswerImagePreview: null,
                        description: "",
                        loading: false
                    })
                } else {
                    swal({
                        title: 'Image cannot be uploaded',
                        text: "Something went wrong, please re-upload your image and try again!",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            })
        } else {
            const answerId = appDatabasePrimaryFunctions.ref().push().key;
            let newAnswerObject = {answerText: newAnswerText, id: answerId, answerImage: newAnswerImagePreview, answerImagePreview: newAnswerImagePreview, order:array.length+1, description: description};
            array = array.concat(newAnswerObject);
            this.setState({
                currentAnswerList: array,
                newAnswerText: "",
                description: "",
                newAnswerImagePreview: null
            })
        }
    }

    editQuestion(event){
        event.preventDefault();
        const array = this.state.questionsList;
        const question = array[event.target.value];
        const topImage = question.topImage;
        let addVideo = false;
        if(question && question.videoLink){
            addVideo = true
        } else if(!question){
            swal({
                title: 'Oh uh!',
                text: 'Something went wrong, refresh the page and try again!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        let advanced = false;
        if(question.showScoreboardPercentages || question.showPhonePercentages || question.answerBackgroundColor || question.answerOutlineColor || question.answerTextColor || question.answerProgressBarClient || question.topImage){
          advanced = true;
        }
        this.setState({
            modal: !this.state.modal,
            selectedQuestion: question,
            currentQuestionTitle: question.questionText,
            questionDescription: question.questionDescription,
            videoLink: question.videoLink || "",
            currentAnswerList: question.answers.slice(0),
            showPhonePercentages: question.showPhonePercentages,
            showScoreboardPercentages: question.showScoreboardPercentages,
            add_video: addVideo,
            answerBackgroundColor: question.answerBackgroundColor,
            answerOutlineColor: question.answerOutlineColor,
            answerTextColor: question.answerTextColor,
            description: question.description,
            answerProgressBarClient: question.answerProgressBarClient,
            advanced: advanced,
            newTopImage: null,
            newTopImagePreview: {
                preview: topImage,
            }
        });
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.questionsList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Question?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this question in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result.value) {
            base.remove('questionsList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        editingTicket: false,
        currentQuestionTitle: '',
        selectedQuestion: {},
        currentAnswerList: [],
        showScoreboardPercentages: null,
        showPhonePercentages: null,
        advanced: false,
        newTopImage: null,
        newTopImagePreview: {},
        description: ''
      });
    }

    editAnswer(answer,index){
        const answerText = answer.answerText;
        const answerImage = answer.answerImage;
        const description = answer.description;
        this.removeFromAnswerArray(index);
        this.setState({
            newAnswerText: answerText,
            description: description,
            newAnswerImagePreview: {
                preview: answerImage
            },
            newAnswerImage: null,
        })
    }

    changeOrder(item, direction){
        let currentAnswerList = this.state.currentAnswerList;
        let originalOrder = item.order;
        if(originalOrder === 1 && direction === 1){
            return
        } else if(originalOrder === currentAnswerList.length && direction ===-1){
            return
        }
        let newOrder = originalOrder - direction;
        for(let currentAnswerIndex in currentAnswerList){
            if(newOrder === currentAnswerList[currentAnswerIndex].order){
                currentAnswerList[currentAnswerIndex].order = originalOrder
            } else if(item.id === currentAnswerList[currentAnswerIndex].id){
                currentAnswerList[currentAnswerIndex].order = newOrder;
            }
        }
        this.setState({
            currentAnswerList: currentAnswerList
        })
    }

    removeFromAnswerArray(index){
        let answerArray = this.state.currentAnswerList;
        answerArray.splice(index, 1);
        while(index < answerArray.length){
            if(answerArray[index]){
                answerArray[index].order = index+1
            } else {
                index = answerArray.length+1;
            }
            index++
        }
        this.setState({
            currentAnswerList: answerArray
        })
    }

    render() {
      const questionsList = this.state.questionsList;
      let currentAnswerList = this.state.currentAnswerList;
      let selectedQuestion = this.state.selectedQuestion;
      const newAnswerImagePreview = this.state.newAnswerImagePreview || {};
      const newTopImagePreview = this.state.newTopImagePreview || {};
      if(Object.keys(selectedQuestion).length === 0){
        selectedQuestion = null;
      }
      if(currentAnswerList.length > 1){
          currentAnswerList = currentAnswerList.sort((a,b)=>a.order-b.order)
      }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none'}}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Questions</p>
                    <p className="admin-subheader-text">These are questions fans will respond to during polling</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Question</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        questionsList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.questionText}</p>
                                <ul style={{textAlign:"left"}}>
                                  {
                                      item.answers.sort((a,b)=>a.order-b.order).map(function(item,t){
                                          return (
                                              <li key={t} style={{listStyle:"none"}}>
                                                  - {item.answerText}
                                                  { item.answerImage &&
                                                  <img src={item.answerImage} width="50px" height="50px" alt=""/>
                                                  }
                                              </li>
                                          )
                                      })
                                  }
                                </ul>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editQuestion.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none'}}/>
              <ModalHeader toggle={this.toggle}>Add Question</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <div className="form-group">
                        <label htmlFor="rewardName">Question</label>
                        <textarea id="currentQuestionTitle" name="currentQuestionTitle" className="form-control" value={this.state.currentQuestionTitle} onChange={this.handleChange} placeholder="What is the number 1 winter sport in Jamaica?"/>
                      </div>
                        <div className="form-group">
                            <label htmlFor="questionDescription">Add Optional Question Description: </label>
                            <textarea id="questionDescription" name="questionDescription" value={this.state.questionDescription} onChange={this.handleChange} className="form-control" placeholder="Add a short description for the question..."></textarea>
                        </div>
                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="add_video" name="add_video" type="checkbox" checked={this.state.add_video} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" htmlFor="add_video" style={{padding:0,margin:0}}>Add Video (Optional) <span style={{display: this.state.add_video?"":"none"}}>-- Recommended: Dimensions = 16:9, Size = 1280x720, less then 20MB</span></label>
                        </div>
                        <div style={{display: this.state.add_video ? "" : "none"}}>
                            <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.fileUploaded? 'none' : ''}}>
                                <div className="col-md-6">
                                    <label htmlFor="videoLink">Video Link</label>
                                    <input type="url" id="videoLink" name="videoLink" className="form-control" value={this.state.videoLink} onChange={this.handleChange} placeholder="https://mywinningvideolink.com"/>
                                </div>
                                <div className="col-md-1">
                                    <h2><b>OR</b></h2>
                                </div>
                                <div className="col-md-2">
                                    <input style={{display:'none'}}  id="raceVideoFile" name="raceVideoFile" type="file" ref={input => {this.raceVideoFile = input; }} onChange={this.handleVideoChange}/>
                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('raceVideoFile').click()} />
                                </div>
                            </div>
                            <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.videoLink? '' : 'none'}}>
                                Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({videoLink: null, fileUploaded:false}, () => {
                                document.getElementById('videoLink').value="";
                            })}> ❌</span>
                                {this.state.add_video && this.state.videoLink && !this.state.videoLength &&
                                    <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                }
                                <ReactPlayer
                                    style={{display: this.state.videoLink? '' : 'none'}}
                                    url={this.state.videoLink}
                                    onDuration={(e)=> this.setState({videoLength: e})}
                                    onError={(e)=>this.setState({videoLength: null})}
                                    muted={true}
                                    playing={true}
                                    controls={true}
                                    preload={"auto"}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>
                      {/*<div className="form-group">*/}
                      {/*    <label htmlFor="advanced">Advanced</label>*/}
                      {/*    <br/>*/}
                      {/*    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>*/}
                      {/*</div>*/}
                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="advanced" name="advanced" type="checkbox" checked={this.state.advanced} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" htmlFor="advanced" style={{padding:0,margin:0}}>Advanced</label>
                        </div>
                      {this.state.advanced &&
                          <div className="container">
                              <h4>Display Percents</h4>
                              <div className="row">
                                  <div className="col-md-12">
                                      <div className="form-group row">
                                          <label className="col-md-12 col-form-label" htmlFor="name3"> Show Percentages On Scoreboard</label>
                                          <div className="col-md-12" onChange={this.handleChange}>
                                              <input type="radio" value="all" name="showScoreboardPercentages" checked={this.state.showScoreboardPercentages === "all"}/> <span style={{marginRight:10, fontSize:10}}>The Entire Game</span>
                                              <input type="radio" value="parttime" name="showScoreboardPercentages" checked={this.state.showScoreboardPercentages === "parttime"}/> <span style={{marginRight:10, fontSize:10}}>After The Game Ends</span>
                                              <input type="radio" value="never" name="showScoreboardPercentages" checked={this.state.showScoreboardPercentages === "never"}/> <span style={{marginRight:10, fontSize:10}}>Never</span>
                                              <input type="radio" value="" name="showScoreboardPercentages" checked={!this.state.showScoreboardPercentages}/> <span style={{fontSize:10}}>Default</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-12">
                                      <div className="form-group row">
                                          <label className="col-md-12 col-form-label" htmlFor="name3"> Show Percentages On Phone</label>
                                          <div className="col-md-12" onChange={this.handleChange}>
                                              <input type="radio" value="all" name="showPhonePercentages" checked={this.state.showPhonePercentages === "all"}/> <span style={{marginRight:10, fontSize:10}}>The Entire Game</span>
                                              <input type="radio" value="parttime" name="showPhonePercentages" checked={this.state.showPhonePercentages === "parttime"}/> <span style={{marginRight:10, fontSize:10}}>After The Game Ends</span>
                                              <input type="radio" value="never" name="showPhonePercentages" checked={this.state.showPhonePercentages === "never"}/> <span style={{marginRight:10, fontSize:10}}>Never</span>
                                              <input type="radio" value="" name="showPhonePercentages" checked={!this.state.showPhonePercentages}/> <span style={{fontSize:10}}>Default</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <h4>Answer Button Colors</h4>
                              <div className="form-inline">
                                  <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerBackgroundColor")}/>
                                  <div style={{backgroundColor: this.state.answerBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                  <label htmlFor="answerBackgroundColor" style={{marginRight: 10}}>Background Color</label>
                                  <input id="answerBackgroundColor" name="answerBackgroundColor" type="text" className="form-control" value={this.state.answerBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                  <div style={{height:'10px', width:'100%'}}/>

                                  <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerTextColor")}/>
                                  <div style={{backgroundColor: this.state.answerTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                  <label htmlFor="answerTextColor" style={{marginRight: 10}}>Text Color</label>
                                  <input id="answerTextColor" name="answerTextColor" type="text" className="form-control" value={this.state.answerTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                  <div style={{height:'10px', width:'100%'}}/>

                                  <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerOutlineColor")}/>
                                  <div style={{backgroundColor: this.state.answerOutlineColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                  <label htmlFor="answerOutlineColor" style={{marginRight: 10}}>Outline Color</label>
                                  <input id="answerOutlineColor" name="answerOutlineColor" type="text" className="form-control" value={this.state.answerOutlineColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                  <div style={{height:'10px', width:'100%'}}/>

                                  <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerProgressBarClient")}/>
                                  <div style={{backgroundColor: this.state.answerProgressBarClient, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                  <label htmlFor="answerProgressBarClient" style={{marginRight: 10}}>Progress Bar Color</label>
                                  <input id="answerProgressBarClient" name="answerProgressBarClient" type="text" className="form-control" value={this.state.answerProgressBarClient} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="companyTitle">Add Top Image (Optional): </label>
                                  <Dropzone
                                      className="dropzone dz-clickable"
                                      accept="image/*"
                                      onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'newTopImage')}}
                                      multiple={false}
                                      maxSize={2200000}>
                                      <div className="dz-message needsclick">
                                          <span className="fa fa-cloud-upload text-muted" style={{display: newTopImagePreview.preview ? 'none' : ''}}/>
                                          <h3 style={{display: newTopImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                          <img
                                              style={{display: newTopImagePreview.preview ? '' : 'none'}}
                                              src={newTopImagePreview.preview}
                                              width="100px"
                                              height="auto"
                                              alt="Drop here"
                                          />
                                      </div>
                                  </Dropzone>
                              </div>
                          </div>
                      }
                      <div className="container">
                          <h4>Answer Details</h4>
                          <div className="form-group">
                            <label htmlFor="companyTitle">Add Answer Text: </label>
                            <input id="answerText" name="newAnswerText" type="text" value={this.state.newAnswerText} onChange={this.handleChange} className="form-control" placeholder="The Answer To Your Question"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="answerDescription">Add Optional Answer Description (max {this.state.answerDescriptionLength} chars): </label>
                              <textarea id="answerDescription" name="description" maxLength="250" value={this.state.description} onChange={this.handleChange} className="form-control" placeholder="Add a short description for the answer..."></textarea>
                          </div>
                          <div className="form-group">
                            <label htmlFor="companyTitle">Add Answer Image (Optional): </label>
                            <Dropzone
                                className="dropzone dz-clickable"
                                accept="image/*"
                                onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'newAnswerImage')}}
                                multiple={false}
                                maxSize={2200000}>
                                <div className="dz-message needsclick">
                                    <span className="fa fa-cloud-upload text-muted" style={{display: newAnswerImagePreview.preview ? 'none' : ''}}/>
                                    <h3 style={{display: newAnswerImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                    <img
                                        style={{display: newAnswerImagePreview.preview ? '' : 'none'}}
                                        src={newAnswerImagePreview.preview}
                                        width="100px"
                                        height="auto"
                                        alt="Drop here"
                                    />
                                </div>
                            </Dropzone>
                          </div>
                      </div>
                      <center>
                        <button className="btn btn-primary btn-lg" onClick={()=>this.answerToLocalArray()}><span className="fa fa-plus"/> ADD ANSWER</button>
                      </center>
                      <ol>
                        {
                          currentAnswerList.map(function(item,index){
                              return(
                                  <li key={index}>Answer: {item.answerText}
                                    <img src={item.answerImagePreview || item.answerImage} width="50px" height="50px" alt="" style={{display:item.answerImage?"":"none"}}/>
                                    <span style={{marginLeft:"40px"}}>
                                        <div style={{display:"inline-block", marginLeft:10}}>
                                            <button style={{display: index === 0?"none":""}} onClick={()=>this.changeOrder(item, 1)}>⬆</button>
                                            <button style={{display: currentAnswerList.length === index+1? "none":""}} onClick={()=>this.changeOrder(item, -1)}>⬇</button>
                                        </div>
                                        <button className="btn btn-primary btn-admin" style={{marginLeft:"40px"}} onClick={()=>this.editAnswer(item,index)}><span className="fa fa-pencil"/></button>
                                        <button className="btn btn-danger btn-admin" style={{float:"right"}} onClick={()=>this.removeFromAnswerArray(index)}><span className="fa fa-trash"/></button>
                                    </span>
                                  </li>
                              )
                          }, this)
                        }
                      </ol>
                      <div className="form-group text-center">
                        <button className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.createQuestion()}>Submit Question</button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.colorPickerShow} id="myModal">
                <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpQuestions
